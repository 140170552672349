import { httpsCallable } from "firebase/functions";
import { Functions } from "@firebase/functions";
import { Employee } from "@/model/employee";
import { EmployeeMapper } from "@/model/mapper/employee-mapper";
import { Organisation } from "@/model/organisation";
import { OrganisationMapper } from "@/model/mapper/organisation-mapper";
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;

export class ApiService {
  functions: Functions;

  constructor(functions: Functions) {
    this.functions = functions;
  }

  async createEmployees(organisationId: string, employees: Employee[]) {
    const createEmployees = httpsCallable(this.functions, "createEmployees");
    return await createEmployees({
      organisationIdentifier: organisationId,
      employees: employees.map((employee) => {
        return EmployeeMapper.toData(employee);
      }),
    });
  }

  async editEmployees(
    organisationId: string,
    employeeData: {
      employeeId: string;
      firstName?: string | null;
      lastName?: string | null;
      emailAddress?: string | null;
      isActive?: boolean;
      lastInviteDate?: Timestamp;
    }[]
  ) {
    const editEmployees = httpsCallable(this.functions, "editEmployees");
    const data: { [field: string]: any } = {};
    data.organisationId = organisationId;
    data.employeeData = employeeData;
    return await editEmployees(data);
  }

  async inviteEmployees(
    organisationId: string,
    organisationName: string,
    employeeData: {
      employeeId: string;
      emailAddress: string;
      firstName?: string | null;
    }[]
  ) {
    const inviteEmployees = httpsCallable(this.functions, "inviteEmployees");
    const data: { [field: string]: any } = {};
    data.organisationId = organisationId;
    data.organisationName = organisationName;
    data.employeeData = employeeData;
    return await inviteEmployees(data);
  }

  async createOrganisation(organisation: Organisation) {
    const createOrganisation = httpsCallable(
      this.functions,
      "createOrganisation"
    );
    return await createOrganisation(OrganisationMapper.toData(organisation));
  }

  async editOrganisation(
    organisationId: string,
    name?: string | null,
    isActive?: boolean
  ) {
    const editOrganisation = httpsCallable(this.functions, "editOrganisation");
    const data: { [field: string]: any } = {};
    data.organisationIdentifier = organisationId;
    if (name !== undefined) {
      data.name = name;
    }
    if (isActive !== undefined) {
      data.isActive = isActive;
    }
    return await editOrganisation(data);
  }
}
