import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { useUserStore } from "@/stores/user-store";
import LoginView from "@/views/LoginView.vue";
import AdministratorsView from "@/views/administrators/AdministratorsList.vue";
import OrganisationsList from "@/views/organisations/OrganisationsList.vue";
import OrganisationsRoot from "@/views/organisations/OrganisationsRoot.vue";
import EmployeeList from "@/views/organisations/employees/EmployeeList.vue";
import AcceptInvitationView from "@/views/administrators/AcceptInvitationView.vue";
import EmployeeDetail from "@/views/organisations/employees/EmployeeDetail.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/administrators",
    name: "administrators",
    component: AdministratorsView,
  },
  {
    path: "/organisations",
    name: "organisations",
    component: OrganisationsRoot,
    meta: { breadCrumb: "Organisations" },
    children: [
      {
        path: "",
        component: OrganisationsList,
      },
      {
        path: ":organisationId",
        component: OrganisationsRoot,
        children: [
          {
            path: "",
            component: EmployeeList,
            name: "employeeList",
          },
          {
            path: ":employeeId",
            component: EmployeeDetail,
            name: "employeeDetail",
          },
        ],
      },
    ],
  },
  {
    path: "/accept-invite/:inviteType/:token",
    name: "invite",
    component: AcceptInvitationView,
    meta: { showRoute: false },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const store = useUserStore();
  if (store.user == null && to.name !== "login") {
    return { name: "login", query: { redirect: to.path } };
  }
});

export default router;
