import { UserData } from "@/model/user-data";

export class User {
  uid: string;
  displayName: string | null;
  emailAddress: string | null;
  userData: UserData | null;

  constructor(
    uid: string,
    displayName: string | null,
    emailAddress: string | null,
    userData: UserData | null
  ) {
    this.uid = uid;
    this.displayName = displayName;
    this.emailAddress = emailAddress;
    this.userData = userData;
  }
}
