import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid min-vh-100 d-flex flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "row flex-grow-1"
}
const _hoisted_3 = {
  class: "col-3 p-4 shadow-5",
  style: {"background":"white"}
}
const _hoisted_4 = { class: "col-9" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "row p-4" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "row p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_NavigationBar = _resolveComponent("NavigationBar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.store.user != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SideBar)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_NavigationBar)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_router_view)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_router_view)
          ])
        ]))
  ]))
}