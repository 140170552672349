import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { initializeFirebase } from "@/utilties/initialize-firebase";
import "./assets/custom.css";

createApp(App).use(createPinia()).use(router).mount("#app");

initializeFirebase();
