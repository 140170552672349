import { defineStore } from "pinia";
import { User } from "@/model/user";

export const useUserStore = defineStore({
  id: "userStore",
  state: () => {
    return {
      user: null as User | null,
      authenticating: true,
    };
  },
  actions: {
    setUser(user: User | null) {
      this.user = user;
      this.authenticating = false;
    },
  },
});
