import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { useUserStore } from "@/stores/user-store";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { UserData } from "@/model/user-data";
import { User } from "@/model/user";
import { Functions } from "firebase/functions";
import { Auth } from "firebase/auth";
import { Firestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

export function initializeFirebase() {
  const app = initializeApp(firebaseConfig);

  const auth = getAuth(app);
  const functions = getFunctions(app);
  const firestore = getFirestore(app);

  if (process.env.VUE_APP_USE_EMULATOR == "true") {
    console.log("Using emulator");
    initEmulators(functions, auth, firestore);
  }

  const userStore = useUserStore();

  auth.onAuthStateChanged((firebaseUser) => {
    if (firebaseUser == null) {
      userStore.setUser(null);
    } else {
      userStore.setUser(
        new User(
          firebaseUser.uid,
          firebaseUser.displayName,
          firebaseUser.email,
          new UserData([])
        )
      );
    }
  });
}

function initEmulators(functions: Functions, auth: Auth, firestore: Firestore) {
  connectFunctionsEmulator(
    functions,
    process.env.VUE_APP_FUNCTIONS_EMULATOR_HOST!,
    parseInt(process.env.VUE_APP_FUNCTIONS_EMULATOR_PORT!)
  );
  connectAuthEmulator(auth, process.env.VUE_APP_AUTH_EMULATOR_ADDRESS!);
  connectFirestoreEmulator(
    firestore,
    process.env.VUE_APP_FIRESTORE_EMULATOR_HOST!,
    parseInt(process.env.VUE_APP_FIRESTORE_EMULATOR_PORT!)
  );
}
