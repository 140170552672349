export class ValidationError {
  field: string;
  reason: ValidationReason;

  constructor(field: string, reason: ValidationReason) {
    this.field = field;
    this.reason = reason;
  }
}

export enum ValidationReason {
  Missing,
  Conflict,
}

export function validationMessage(validationReason: ValidationReason): string {
  switch (validationReason) {
    case ValidationReason.Missing:
      return "Required";
    case ValidationReason.Conflict:
      return "Conflict";
  }
}
