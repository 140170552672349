
import { useUserStore } from "@/stores/user-store";
import SideBar from "@/components/SideBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";

export default {
  components: { NavigationBar, SideBar },
  setup() {
    const store = useUserStore();

    return { store };
  },
};
