<template>
  <div class="row">
    <img
      class="img-fluid float-start"
      style="max-width: 50%"
      src="@/assets/Logo+Header@2x.png"
    />
  </div>

  <ul class="list-unstyled mt-5">
    <li>
      <router-link class="btn normal-case btn-link btn-block text-start" to="/">
        <span class="d-none d-md-inline-block">Home</span>
      </router-link>
      <router-link
        class="btn btn normal-case btn-link btn-block text-start"
        to="/organisations"
      >
        <span class="d-none d-md-inline-block">Organisations</span>
      </router-link>
      <router-link
        class="btn btn normal-case btn-link btn-block text-start"
        to="/administrators"
      >
        <span class="d-none d-md-inline-block">Administrators</span>
      </router-link>
    </li>
  </ul>
</template>
