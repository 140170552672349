import { defineStore } from "pinia";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { Organisation } from "@/model/organisation";
import firebase from "firebase/compat";
import { FirestoreUser } from "@/model/firestore-user";
import { UserData } from "@/model/user-data";
import { Role } from "@/model/role";
import { getFunctions, httpsCallable } from "firebase/functions";
import { User } from "@/model/user";
import Unsubscribe = firebase.Unsubscribe;
import { OrganisationMapper } from "@/model/mapper/organisation-mapper";

export type FirebaseState = {
  organisations: Organisation[];
  administrators: User[];
  organisationSnapshotSubscription: Unsubscribe | null;
  administratorSnapshotSubscription: Unsubscribe | null;
  loadingOrganisations: boolean;
  loadingAdministrators: boolean;
};

export const useFirebaseStore = defineStore({
  id: "firebaseStore",
  state: () =>
    ({
      organisations: [],
      administrators: [],
      organisationSnapshotSubscription: null,
      administratorSnapshotSubscription: null,
      loadingOrganisations: true,
      loadingAdministrators: true,
    } as FirebaseState),
  actions: {
    subscribeOrganisations() {
      this.loadingOrganisations = true;
      const db = getFirestore();

      const q = query(collection(db, "organisations"));
      this.organisationSnapshotSubscription = onSnapshot(
        q,
        (querySnapshot) => {
          this.organisations = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            return OrganisationMapper.toOrganisation(doc.id, data);
          });
          this.loadingOrganisations = false;
        },
        (error) => {
          console.log("Failed to query organisations", error);
        }
      );
    },
    unsubscribeOrganisations() {
      if (this.organisationSnapshotSubscription != null) {
        this.organisationSnapshotSubscription();
      }
    },
    subscribeAdministrators() {
      this.loadingAdministrators = true;
      const db = getFirestore();

      const q = query(
        collection(db, "users"),
        where("roles", "array-contains", "admin")
      );
      this.administratorSnapshotSubscription = onSnapshot(
        q,
        async (querySnapshot) => {
          const firestoreUsers = querySnapshot.docs.map(
            (doc) => new FirestoreUser(doc.id, doc.data() as Role[])
          );

          const ids = firestoreUsers.map((firestoreUser) => {
            return firestoreUser.uid;
          });

          const userRoleMap: Map<string, Role[]> = new Map(
            firestoreUsers.map((firestoreUser) => [
              firestoreUser.uid,
              firestoreUser.roles,
            ])
          );

          const functions = getFunctions();
          const getUsers = httpsCallable(functions, "getUsers");
          this.administrators = await getUsers({ ids: ids })
            .then((users) => {
              return (users.data as [{ [key: string]: string }]).map((user) => {
                return new User(
                  user.uid,
                  user.displayName,
                  user.emailAddress,
                  new UserData(userRoleMap.get(user.uid) ?? [])
                );
              });
            })
            .catch((error) => {
              console.log("getUsers failed", error);
              return [];
            });
          this.loadingAdministrators = false;
        },
        (error) => {
          console.log("Failed to query administrators", error);
        }
      );
    },
    unsubscribeAdministrators() {
      if (this.administratorSnapshotSubscription != null) {
        this.administratorSnapshotSubscription();
      }
    },
  },
});
