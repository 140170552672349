import { Organisation, ValidationType } from "@/model/organisation";
import { Employee, EmployeeStatus } from "@/model/employee";

export class EmailUtils {
  static sendEmailAllowed(
    organisation: Organisation | null,
    employee: Employee | null
  ): boolean {
    if (organisation == null) {
      return false;
    }
    if (employee == null) {
      return false;
    }
    let emailAddress = employee.emailAddress;
    if (organisation.validation.type == ValidationType.email) {
      emailAddress = employee.id;
    }
    const state = employee.status();
    return (
      emailAddress != null &&
      emailAddress != "" &&
      state.status != EmployeeStatus.active &&
      state.status != EmployeeStatus.disabled
    );
  }
}
