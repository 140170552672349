import { Role } from "@/model/role";

export class FirestoreUser {
  uid: string;
  roles: Role[];

  constructor(uid: string, roles: Role[]) {
    this.uid = uid;
    this.roles = roles;
  }
}
