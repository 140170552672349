export class AccessCode {
  static charset = [
    "3",
    "6",
    "7",
    "C",
    "D",
    "F",
    "G",
    "H",
    "J",
    "K",
    "M",
    "N",
    "P",
    "R",
    "T",
    "W",
    "X",
  ];

  /**
   * Generates a random 'code' using the charset above
   * @param length the desired length of the access code in chars
   */
  static generate(length: number): string {
    let text = "";
    for (let i = 0; i < length; i++) {
      const index = Math.floor(Math.random() * this.charset.length);
      text += this.charset[index];
    }

    return text;
  }
}
