/**
 * Maps data to an EmployeeUser object
 *
 * @throws Error
 */
import { Employee } from "@/model/employee";

export class EmployeeMapper {
  static toEmployee(
    employeeId: string,
    data: { [field: string]: any }
  ): Employee {
    if (!employeeId) {
      throw Error("Missing employee id");
    }
    return new Employee(
      employeeId,
      data.firstName ?? null,
      data.lastName ?? null,
      data.emailAddress ?? null,
      data.isActive ?? false,
      data.lastInviteDate ?? null,
      data.hasAcceptedTermsAndConditions ?? false
    );
  }

  static toData(employee: Employee): { [field: string]: any } {
    if (!employee.id) {
      throw Error("Missing employee id");
    }
    return {
      employeeIdentifier: employee.id,
      firstName: employee.firstName,
      lastName: employee.lastName,
      emailAddress: employee.emailAddress,
      isActive: employee.isEnabled,
      lastInviteDate: employee.lastInviteDate,
      hasAcceptedTermsAndConditions: employee.hasAcceptedTermsAndConditions,
    };
  }
}
