<template>
  <div class="row text-center">
    <div class="col">
      <img src="@/assets/Logo+Header@2x.png" />
      <div class="row mt-4">
        <span>Welcome to The People Spot Admin Portal</span>
      </div>
    </div>
  </div>
</template>
