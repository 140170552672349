
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useUserStore } from "@/stores/user-store";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const store = useUserStore();
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    const router = useRouter();
    const route = useRoute();

    store.$subscribe((mutation, state) => {
      if (state.user != null) {
        router.replace(route.query.redirect as string);
      }
    });

    return {
      store,
      provider,
      auth,
    };
  },
  methods: {
    signIn() {
      signInWithPopup(this.auth, this.provider).catch((error) => {
        console.log("Failed to sign in", error);
      });
    },
    signOut() {
      getAuth().signOut();
    },
  },
});
