import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;
import { DateTime } from "luxon";

export class Employee {
  id: string;
  firstName: string | null;
  lastName: string | null;
  emailAddress: string | null;
  isEnabled: boolean;
  lastInviteDate: Timestamp | null;
  hasAcceptedTermsAndConditions: boolean;

  constructor(
    id: string,
    firstName: string | null,
    lastName: string | null,
    emailAddress: string | null,
    isEnabled: boolean,
    lastInviteDate: Timestamp | null,
    hasAcceptedTermsAndConditions: boolean
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.emailAddress = emailAddress;
    this.isEnabled = isEnabled;
    this.lastInviteDate = lastInviteDate;
    this.hasAcceptedTermsAndConditions = hasAcceptedTermsAndConditions;
  }

  status(): EmployeeStatusInfo {
    if (!this.isEnabled) {
      return { status: EmployeeStatus.disabled, message: "" };
    }
    if (this.hasAcceptedTermsAndConditions) {
      return { status: EmployeeStatus.active, message: "" };
    } else {
      const date = this.lastInviteDate?.toDate() ?? null;
      if (date != null) {
        return {
          status: EmployeeStatus.invited,
          message: `Invited ${DateTime.fromJSDate(date).toRelative()}`,
        };
      } else {
        return { status: EmployeeStatus.enabled, message: "" };
      }
    }
  }

  friendlyName(): string {
    if (this.firstName != null) {
      return this.firstName + " " + this.lastName;
    } else {
      return this.id;
    }
  }
}

export enum EmployeeStatus {
  enabled = "enabled",
  invited = "invited",
  active = "active",
  disabled = "disabled",
}

export interface EmployeeStatusInfo {
  status: EmployeeStatus;
  message: string;
}
