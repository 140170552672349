export class Organisation {
  id: string;
  name: string;
  isActive: boolean;
  analyticsEnabled: boolean;
  validation: Validation;

  constructor(
    identifier: string,
    name: string,
    isActive: boolean,
    analyticsEnabled: boolean,
    validation: Validation
  ) {
    this.id = identifier;
    this.name = name;
    this.isActive = isActive;
    this.analyticsEnabled = analyticsEnabled;
    this.validation = validation;
  }
}

export enum ValidationType {
  idCode = "ID Code",
  email = "Email Address",
}

export class Validation {
  type: ValidationType;
  example: string;

  constructor(type: ValidationType, example: string) {
    this.type = type;
    this.example = example;
  }
}
