/**
 * Maps data to an Organisation object
 *
 * @throws Error
 */
import { Organisation, Validation } from "@/model/organisation";

export class OrganisationMapper {
  static toOrganisation(
    organisationId: string,
    data: { [field: string]: any }
  ): Organisation {
    if (!organisationId) {
      throw Error("Missing identifier");
    }
    if (!data.name) {
      throw Error("Missing name");
    }
    return new Organisation(
      organisationId,
      data.name,
      data.isActive ?? false,
      data.analyticsEnabled ?? false,
      ValidationMapper.toValidation(data.validation)
    );
  }

  static toData(organisation: Organisation): { [field: string]: any } {
    if (!organisation.id) {
      throw Error("Missing organisation id");
    }
    return {
      organisationIdentifier: organisation.id,
      name: organisation.name,
      isActive: organisation.isActive,
      analyticsEnabled: organisation.analyticsEnabled,
      validation: ValidationMapper.toData(organisation.validation),
    };
  }
}

export class ValidationMapper {
  static toValidation(data: { [field: string]: any }): Validation {
    return new Validation(data.type, data.example);
  }
  static toData(validation: Validation): { [field: string]: any } {
    return {
      type: validation.type,
      example: validation.example,
    };
  }
}
